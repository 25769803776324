
import React from "react"
import { Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

class ProjectGrid extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const isMobile = this.props.isMobile;
    var posts = this.props.posts;
    var featuredPostList = posts.map(function(post, index){
        if (isMobile) {
          return(
            <Col md={6} sm={6} lg={6} xl={6} xs={12} className="post-col">
              <a className="post-link" href={"/project/"+post.postTitle}>
                <GatsbyImage image={post.featuredImage.gatsbyImageData} className="post-img" style={{height:`80vh`}}/>
              </a>
              <Row className="post-title">
                <Col md={10} sm={10} lg={10} xl={10} xs={10}>
                  <a className="post-link" href={"/project/"+post.postTitle}>
                    <h2>{post.postTitle}</h2>
                  </a>
                </Col>
              </Row>
            </Col>
          )
        } else {
          return(
            <Col md={6} sm={6} lg={6} xl={6} xs={12} className="post-col">
              <a className="post-link" href={"/project/"+post.postTitle}>
                <GatsbyImage image={post.featuredImage.gatsbyImageData} className="post-img" style={{height:`80vh`}}/>
              </a>
              <Row className="post-title">
                <Col md={10} sm={10} lg={10} xl={10} xs={10}>
                  <a className="post-link" href={"/project/"+post.postTitle}>
                    <h1 className="display-6">{post.postTitle}</h1>
                  </a>
                </Col>
              </Row>
            </Col>
          )
        }
    })

    return(
      <Row className="post mx-auto">
      <Row className="mx-auto h-100">
        {featuredPostList}
      </Row>
      </Row>
    )
  }
}

export default ProjectGrid
